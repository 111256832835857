import Link from 'next/link';

const EventTile = ({ event }) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="single-events-box-item">
                <div className="image">
                    <Link href={`/webinar/${event.slug}`}>
                        <img src={event.imageUrl} alt="image" loading="lazy" className="d-block" />
                    </Link>
                    <span className="date">{event.eventDateTime}</span>
                </div>
                {/* <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${event.progress}%` }} aria-valuenow={event.progress} aria-valuemin="0" aria-valuemax="100">{event.progress}% Booked</div>
                </div> */}
                {/* <div className="slots-left">Hurry! {event.slotsLeft} slots left</div> */}
                <div className="content">
                    <h3>
                        <Link href={`/webinar/${event.slug}`}>
                            <a>{event.name}</a>
                        </Link>
                    </h3>
                    <span className="location">
                        {/* <i className="bx bx-map"></i> {event.location} */}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EventTile;
