import React from 'react';
import Link from 'next/link';

const MainBanner = () => {
    return (
        <div className="health-coaching-banner-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="health-coaching-banner-image">
                            <img src="/images/Ayushi_desk.webp" alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="health-coaching-banner-content">
                            <h1>SKYROCKET YOUR STUDIES AND CAREER WITH US</h1>
                            <p>Learn the fastest and simplest ways to study productively with focus, and clear your exams smoothly without studying for long hours or giving up on your passion and hobbies.</p>
                            <div className="btn-box">
                                <div className="d-flex align-items-center">
                                    <Link href="/programs">
                                        <a className="default-btn">
                                            <i className="flaticon-user"></i> View Courses <span></span>
                                        </a>
                                    </Link>
                                    {/* <img src="/images/signature.png" className="signature" alt="image" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="health-coaching-shape1">
                {/* <img src="/images/health-coaching-shape1.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape2">
                {/* <img src="/images/health-coaching-shape2.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape3">
                {/* <img src="/images/health-coaching-shape3.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape4">
                {/* <img src="/images/health-coaching-shape4.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape5">
                {/* <img src="/images/health-coaching-shape5.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape6">
                {/* <img src="/images/health-coaching-shape6.png" alt="image" /> */}
            </div>
            <div className="health-coaching-shape7">
                <img src="/images/health-coaching-shape7.png" alt="image" />
            </div>

            <div className="divider"></div>
        </div>
    )
}

export default MainBanner;