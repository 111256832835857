import React from 'react';
import Link from 'next/link';
import { ProgramApi } from '../dashboard/postService';

const PopularCourses = () => {
    const [courses, setCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchCourses = async () => {
            try {
                // Fetch the response from the API
                const response = await ProgramApi.getFeaturedPrograms();
                console.log('API Response:', response); // Debugging log

                if (response.success && Array.isArray(response.data)) {
                    const programs = response.data;

                    // Map the API response to match the courseData format
                    const mappedCourses = programs.map((program) => ({
                        id: program.slug, // Using slug as a unique identifier
                        image: program.imageUrl,
                        price: "$49", // Hardcoded
                        title: program.title,
                        description: "A great course to enhance your skills.", // Hardcoded
                        lessons: program.lessons || 0,
                        students: Math.floor(Math.random() * 200) + 50, // Randomized for now
                        rating: 4.5, // Hardcoded
                        ratingCount: Math.floor(Math.random() * 100) + 50, // Randomized
                    }));

                    setCourses(mappedCourses);
                } else {
                    console.error("Unexpected API response format:", response);
                }
            } catch (error) {
                console.error("Failed to fetch courses:", error);
            } finally {
                setLoading(false); // Stop loading once done
            }
        };

        fetchCourses();
    }, []);

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;
        return (
            <>
                {Array.from({ length: fullStars }, (_, index) => (
                    <i className="bx bxs-star" key={`full-${index}`}></i>
                ))}
                {halfStar > 0 && <i className="bx bxs-star-half"></i>}
                {Array.from({ length: emptyStars }, (_, index) => (
                    <i className="bx bx-star" key={`empty-${index}`}></i>
                ))}
            </>
        );
    };

    return (
        <div className="courses-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Go At Your Own Pace</span>
                    <h2>Our Popular Courses</h2>
                </div>
                {loading ? (
                    <p>Loading courses...</p> // Show loading message
                ) : courses.length === 0 ? (
                    <p>No courses available at the moment.</p> // Show fallback if no courses
                ) : (
                    <div className="row">
                        {courses.map((course) => (
                            <div key={course.id} className="col-lg-4 col-md-6">
                                <div className="single-courses-box">
                                    <div className="courses-image">
                                        <Link href={`/single-courses/${course.id}`}>
                                            <a className="d-block image">
                                                <img src={course.image} alt={course.title} />
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="courses-content">
                                        {/* <div className="rating">
                                            {renderStars(course.rating)}
                                            <div className="rating-total">
                                                {course.rating} ({course.ratingCount} ratings)
                                            </div> */}
                                        {/* </div> */}
                                        <h3>
                                            <Link href={`/single-courses/${course.id}`}>
                                                <a>{course.title}</a>
                                            </Link>
                                        </h3>
                                        {/* <p>{course.description}</p> */}
                                        {/* <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                            <li>
                                                <i className="flaticon-agenda"></i> {course.lessons} Lessons
                                            </li>
                                            <li>
                                                <i className="flaticon-people"></i> {course.students} Students
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PopularCourses;
