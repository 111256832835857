import React, { useEffect } from 'react';
import Navbar from '../components/_App/NavbarOriginal';
import MainBanner from '../components/HealthCoaching/MainBanner';
import FunFacts from '../components/HealthCoaching/FunFacts';
import DownloadApp from '../components/HealthCoaching/DownloadApp';
import LifestyleCourses from '../components/HealthCoaching/LifestyleCourses';
import ChooseProgram from '../components/HealthCoaching/ChooseProgram';
import HealthServices from '../components/HealthCoaching/HealthServices';
import AboutUs from '../components/HealthCoaching/AboutUs';
import TopSellingCourses from '../components/HealthCoaching/TopSellingCourses';
import PopularCourses from '../components/HealthCoaching/PopularCourses';
import UpcomingEvents from '../components/HealthCoaching/UpcomingEvents';
import LatestBlog from '../components/HealthCoaching/LatestBlog';
import SubscribeForm from '../components/HealthCoaching/SubscribeForm';
import ProgramInstructor from '../components/programs/ProgramInstructor';
import { pagedata } from '../data/program/ayushi-eps';
import ProgramReviews from '../components/programs/ProgramReviews';
// import Navbar from '../components/_App/Ayushi_nav'
// import Footer from '../components/_App/Footer';
import { useRouter } from "next/router";
import theme from '@material-tailwind/react/theme';

const Index8 = () => {
    const router = useRouter();

    // useEffect(() => {
    //     router.push('/dashboard', undefined, { shallow: true });
    // }, []);


    useEffect(() => {
        // Check if there's a hash in the URL
        if (window.location.hash === '#about-us') {
            // Small timeout to ensure the content is loaded
            setTimeout(() => {
                const aboutSection = document.getElementById('about-us');
                if (aboutSection) {
                    aboutSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, []); // Empty dependency array means this runs once when component mounts




    return (
        <React.Fragment>
            <Navbar />
            <MainBanner />
            <UpcomingEvents />
            <div id="about-us">
                <ProgramInstructor data={pagedata} theme={pagedata.theme} />
            </div>
            <PopularCourses />
            <ProgramReviews data={pagedata} theme={pagedata.theme} />
            {/* <FunFacts /> */}
            {/* <LifestyleCourses /> */}
            {/* <DownloadApp /> */}
            {/* <ChooseProgram /> */}
            {/* <HealthServices /> */}
            {/* <AboutUs /> */}

            {/* <Feedback /> */}

            {/* <LatestBlog /> */}
            {/* <SubscribeForm /> */}
            {/* <Footer /> */}
        </React.Fragment>
    )
}

export default Index8;