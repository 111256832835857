import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import EventTile from '../events/EventTile';
import { EventsApi } from '../../utils/eventsApi';

const UpcomingEvents = () => {
    const [eventsList, setEventsList] = useState({
        // upcoming: [],
        // myEvents: myEventsData,
        // pastEvents: pastEventsData,
    });
    const [events, setEvents] = useState([
        // {
        //     id: 1,
        //     title: "Global Conference on Business Management",
        //     imageUrl: "/images/events/health-img1.jpg",
        //     date: "Wed, 20 May, 2020",
        //     location: "Vancover, Canada",
        //     progress: 25,
        //     slotsLeft: 213,
        // },
        // {
        //     id: 2,
        //     title: "International Conference on Teacher Education",
        //     imageUrl: "/images/events/health-img2.jpg",
        //     date: "Tue, 19 May, 2020",
        //     location: "Sydney, Australia",
        //     progress: 50,
        //     slotsLeft: 100,
        // },
        // {
        //     id: 3,
        //     title: "International Conference on Special Needs Education",
        //     imageUrl: "/images/events/health-img3.jpg",
        //     date: "Mon, 18 May, 2020",
        //     location: "Istanbul, Turkey",
        //     progress: 85,
        //     slotsLeft: 35,
        // },
    ]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await EventsApi.getList();
                // console.log('API Response:', response); // Debug log
                
                // Check for success: true and data array
                if (response && response.success && Array.isArray(response.data)) {
                    setEvents(response.data);
                    // console.log("Events set:", response.data);
                } else {
                    setError('Failed to fetch events');
                }
            } catch (err) {
                console.error('Error details:', err); // Debug log
                setError(err.message || 'An error occurred while fetching events');
            } finally {
                setLoading(false);
            }
        };
    
        fetchEvents();
    }, []);
    
    

    // return (
    //     <div className="events-area bg-image bg-eee8df pt-100 pb-70">
    //         <div className="container">
    //             <div className="section-title">
    //                 <span className="sub-title">Events</span>
    //                 <h2>Our Upcoming Events</h2>
    //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    //             </div>

    //             <div className="row">
    //                 {events.map(event => (
    //                     <EventTile key={event.id} event={event} /> // Use EventTile component
    //                 ))}
    //             </div>
    //         </div>
    //     </div>
    // );
    return (
        <div className="events-area  bg-eee8df pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Events</span>
                    <h2>Our Upcoming Events</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>

                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <div className="row">
                        {events && events.map(event => (
                            <EventTile key={event.id} event={event} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

}



export default UpcomingEvents;