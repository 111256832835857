import authAPI from '../axios-setup/auth-service';

const successResponse = (data=undefined) => {
    return {'success': true, 'data': data };
}

const errorResponse = (message=undefined) => {
    return {'success': false, 'message': message };
}

const apiCall = async (data) => {
    return await authAPI.post("/api/v1/app/mapper", data);
}


export const EventsApi = {
  getList: async () => {
    try{
      const res = await apiCall({
        method: "get",
        url: "events/list",
      })
      if(res.data.status === 'success' || res.data.success){
        return successResponse(res.data.data.events);
      }else{
        return errorResponse();
      }
    } catch(error) {
      console.log(error);
      return errorResponse();
    }
  },
  generateOrder: async(payload) => {
    try{
      const res = await apiCall({
        method: "post",
        url: "generate-razorpay-order",
        data: payload
      })

      if(res.data.status === 'success' || res.data.success){
        return successResponse(res.data.data);
      }else{
        return errorResponse(res.data.message);
      }
    } catch(error) {
      return errorResponse("Something went wrong. Please try again later.");
    }
  }
}